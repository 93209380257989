* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}


body {
    font-family: 'Montserrat', sans-serif;
    line-height: 1.4;
    background: #fff;
}

.login-area {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(90deg, #9796F0 0%, #FBC7D4 100%);
}

.login-main {
    text-align: center;
}

.login-logo {
    margin-bottom: 40px;
    text-align: center;
}

.login-container {
    text-align: left;
    background-color: #fff;
    width: 400px;
    margin: auto;
    padding: 36px 48px 48px 48px;
    border-radius: 11px;
    box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.15);
}

.login-title {
    padding: 15px;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
}

.login-form {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 16px;
}

.login-form label {
    display: block;
    margin-bottom: 8px;
}

.login-form input {
    width: 100%;
    padding: 0.7rem 1.2rem;
    border-radius: 9px;
    background-color: #f2efee;
    border: none;
}

.login-form input:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(253, 242, 233, 0.5);
}

.btn--form {
    background: linear-gradient(90deg, #4776E6 0%, #8E54E9 100%);
    color: #fdf2e9;
    align-self: end;
    padding: 8px;
    border: none;
    outline: none;
}

.btn,
.btn:link,
.btn:visited {
    display: inline-block;
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    border-radius: 9px;
    border: none;

    cursor: pointer;
    font-family: inherit;
    transition: all 0.3s;
}

button {}

.btn--form:hover {
    background-color: #4776E6;
    color: #fff;
}

.nav-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    background: linear-gradient(90deg, #4776E6 0%, #8E54E9 100%);
}

.logout-btn {
    background: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: none;
}
h4{
    font-weight: 700;
}
.main-wrapper {
    padding: 60px 0;
}


.form-group-area {
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.15);
}

.product-table thead{
    background: #6768E8;
    color: #fff;
}

.badge-box{
    background: linear-gradient(90deg, #4776E6 0%, #8E54E9 100%);
    color: #fff;
    padding: 10px 20px;
    border-radius: 6px;
}
.product-table tr td:nth-child(3){
    white-space: nowrap;
}
.true-box{
    width: 20px;
    height: 20px;
    background: #29d384;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.false-box{
    width: 20px;
    height: 20px;
    background: #ff0000;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 767px){
    .logo-nav{
        width: 120px;
    }
    .main-wrapper{
        padding: 40px 0;
    }
    .nav-area{
        padding: 10px 16px;
    }
    .logout-btn{
        width: 40px;
        height: 40px;
    }
    .logout-btn img{
        width: 18px;
    }
    .login-container{
        width: 100%;
        padding: 20px 30px 30px 30px;
    }
    .login-logo{
        width: 180px;
    }
}